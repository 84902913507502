define("discourse/plugins/karma-score/discourse/components/delegate-actions", ["exports", "@ember/component", "@ember/object", "discourse/plugins/karma-score/lib/karma-api-client"], function (_exports, _component, _object, _karmaApiClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_obj = {
    label: "",
    profile: {},
    isOpen: false,
    noneLabel: "delegate_actions.dropdown_none_label",
    daoName: "",
    delegateVisible: false,
    voteReasonVisible: false,
    shouldShow: (0, _object.computed)(function () {
      return this.currentUser?.username && (this.siteSettings.Show_delegate_pitch_form && this.siteSettings.Delegate_pitch_thread_id || this.siteSettings.Show_vote_reason_form);
    }),
    async fetchProfile() {
      try {
        const cli = new _karmaApiClient.default(this.daoName);
        const profile = await cli.fetchUser(this.currentUser?.username);
        (0, _object.set)(this, "profile", profile);
      } catch (error) {}
    },
    async init() {
      this._super(...arguments);
      this.fetchProfile();
      this._super(...arguments);
      this.daoName = window.selectedDao;
      const cli = new _karmaApiClient.default(this.daoName, "");
      if (this.session) {
        try {
          await cli.checkHealth();
          const {
            allowance
          } = await cli.isApiAllowed(this.session.csrfToken);
          (0, _object.set)(this, "hasSetApiKey", !!allowance);
        } catch {
          (0, _object.set)(this, "hasSetApiKey", false);
        }
      }
    },
    toggleModal: function (modalId, visibility) {
      const ttl = 100;
      const el = $(`#${modalId}`);
      if (visibility) {
        el.animate({
          opacity: "0",
          transform: "translateY(20px)"
        }, ttl);
        setTimeout(() => el.hide(), ttl * 2);
      } else {
        el.show();
        el.animate({
          opacity: "1",
          transform: "translateY(0)"
        }, ttl);
      }
    },
    onClick() {
      (0, _object.set)(this, "isOpen", !this.isOpen);
    },
    toggleDelegate() {
      this.toggleModal("__karma-vote-pitch-modal", this.delegateVisible);
      (0, _object.set)(this, "delegateVisible", !this.delegateVisible);
    },
    toggleVote() {
      this.toggleModal("__karma-vote-form-modal", this.voteReasonVisible);
      (0, _object.set)(this, "voteReasonVisible", !this.voteReasonVisible);
    }
  }, (_applyDecoratedDescriptor(_obj, "onClick", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onClick"), _obj), _applyDecoratedDescriptor(_obj, "toggleDelegate", [_object.action], Object.getOwnPropertyDescriptor(_obj, "toggleDelegate"), _obj), _applyDecoratedDescriptor(_obj, "toggleVote", [_object.action], Object.getOwnPropertyDescriptor(_obj, "toggleVote"), _obj)), _obj));
});