define("discourse/plugins/karma-score/discourse/components/karma-stats", ["exports", "@ember/component", "@ember/service", "@ember/object", "discourse/plugins/karma-score/lib/stats/index"], function (_exports, _component, _service, _object, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_obj = {
    router: (0, _service.inject)(),
    profile: {},
    wrapperId: "__karma-stats-summary",
    hasSetApiKey: false,
    shouldShowActionButtons: false,
    daoName: "",
    availableDaos: [],
    karmaDelegatorsUrl: (0, _object.computed)(function () {
      return `https://karmahq.xyz/dao/${this.daoName?.toLowerCase()}/delegators/${this.profile.username}`;
    }),
    setProfile(profile) {
      (0, _object.set)(this, "profile", profile);
    },
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'daoName', window.selectedDao?.[0]?.toUpperCase() + window.selectedDao?.slice(1));
      (0, _object.set)(this, 'availableDaos', this.siteSettings.DAO_names?.split(",").map(name => ({
        name,
        select: () => this.selectDao(name)
      })));
    },
    async didReceiveAttrs() {
      this._super(...arguments);
      await this.fetchProfile();
    },
    selectDao(daoName) {
      if (!daoName) {
        return;
      }
      ;
      if (!this.availableDaos.find(d => d.name === daoName)) {
        return;
      }
      ;
      (0, _object.set)(this, 'daoName', daoName);
      window.selectedDao = daoName;
      this.fetchProfile();
    },
    async fetchProfile() {
      const profile = await _index.default.start(30, {
        SiteSettings: this.siteSettings
      }, "#" + this.wrapperId, this.username);
      this.setProfile(profile);
      (0, _object.set)(this, "shouldShowActionButtons", this.session && profile.username && this.currentUser && profile?.username === this.currentUser?.username);
    }
  }, (_applyDecoratedDescriptor(_obj, "selectDao", [_object.action], Object.getOwnPropertyDescriptor(_obj, "selectDao"), _obj), _applyDecoratedDescriptor(_obj, "fetchProfile", [_object.action], Object.getOwnPropertyDescriptor(_obj, "fetchProfile"), _obj)), _obj));
});