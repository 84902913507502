define("discourse/plugins/karma-score/discourse/components/delegate-pitch-form", ["exports", "@ember/component", "@ember/service", "@ember/object", "@ember/runloop", "discourse/plugins/karma-score/lib/post-to-topic", "discourse/plugins/karma-score/lib/delete-post", "discourse/plugins/karma-score/lib/is-eth-address", "discourse/plugins/karma-score/lib/karma-api-client", "discourse/plugins/karma-score/lib/update-post", "discourse/plugins/karma-score/lib/parse-fields", "discourse/plugins/karma-score/lib/get-field-values"], function (_exports, _component, _service, _object, _runloop, _postToTopic, _deletePost, _isEthAddress, _karmaApiClient, _updatePost, _parseFields, _getFieldValues) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_obj = {
    router: (0, _service.inject)(),
    proposalId: "",
    reloadTree: () => {},
    hideButton: true,
    form: {
      publicAddress: ""
    },
    customFields: [],
    fields: [{
      label: "Enter your pitch here",
      type: "text"
    }],
    postId: null,
    profile: {},
    vote: {},
    message: "",
    loading: false,
    visible: false,
    modalId: "__karma-vote-pitch-modal",
    threadId: (0, _object.computed)(function () {
      return +this.siteSettings.Delegate_pitch_thread_id;
    }),
    getFields() {
      const {
        Delegate_pitch_fields: fields
      } = this.siteSettings;
      if (fields) {
        (0, _object.set)(this, "fields", (0, _parseFields.default)(fields));
      }
    },
    init() {
      this._super(...arguments);
      this.getFields();
    },
    onClose: function () {
      (0, _object.set)(this, "visible", false);
    },
    /**
     * @param {"interests"|"languages"} prop
     */
    stringToMultiselect() {
      let str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
      let prop = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "interests";
      const values = str.split(",");
      if (!(values && Array.isArray(values) && this[prop])) {
        return [];
      }
      const unique = new Set();
      values.forEach(name => {
        const idx = this[prop].findIndex(interest => interest.name === name);
        if (idx > -1) {
          unique.add(idx + 1);
        }
      });
      return Array.from(unique);
    },
    dispatchToggleModal() {
      setTimeout(() => {
        this.onClose?.();
        setTimeout(() => {
          (0, _object.set)(this, "message", "");
          (0, _object.set)(this, "errors", []);
        }, 250);
      }, 2000);
    },
    checkErrors() {
      (0, _object.set)(this, "errors", []);
      const errors = this.errors;
      if ((0, _getFieldValues.createPostTextFromFields)(this.fields) < 20) {
        errors.push("Your message should have at least 20 chars.");
      }
      if (!(0, _isEthAddress.isEthAddress)(this.form.publicAddress)) {
        errors.push("The provided public address for delegate is invalid");
      }
      (0, _object.set)(this, "errors", errors);
      return !!errors.length;
    },
    isOutside(e) {
      if (!$(e.target).closest(".modal-content").length) {
        this.onClose();
      }
    },
    async fetchDelegatePitch() {
      let isDefaultFields = true;
      if (this.profile.address) {
        const karma = new _karmaApiClient.default(this.siteSettings.DAO_names, this.profile.address);
        try {
          const {
            delegatePitch
          } = await karma.fetchDelegatePitch();
          if (delegatePitch) {
            isDefaultFields = false;
            const fields = (0, _getFieldValues.valuesToFields)(this.fields, delegatePitch.customFields || []);
            (0, _object.set)(this, "customFields", fields);
            (0, _object.set)(this, "postId", delegatePitch.postId);
          }
        } catch {}
      }
      if (isDefaultFields) {
        (0, _object.set)(this, "customFields", this.fields);
      }
    },
    async post() {
      let postId = this.postId;
      const karma = new _karmaApiClient.default(this.siteSettings.DAO_names, this.form.publicAddress);
      try {
        const body = (0, _getFieldValues.createPostTextFromFields)(this.customFields);
        if (postId) {
          await (0, _updatePost.default)({
            postId: this.postId,
            body,
            csrf: this.session.csrfToken
          });
        } else {
          const {
            id
          } = await (0, _postToTopic.default)({
            threadId: this.threadId,
            body,
            csrf: this.session.csrfToken
          });
          postId = id;
        }
      } catch (error) {
        throw new Error("We couldn't post your pitch");
      }
      try {
        const customFields = (0, _getFieldValues.getFieldValues)(this.customFields);
        await karma.saveDelegatePitch({
          threadId: this.threadId,
          customFields,
          discourseHandle: this.currentUser.username,
          postId
        }, this.session.csrfToken, !!this.postId);
        (0, _object.set)(this, "postId", postId);
      } catch (error) {
        if (!this.postId && postId) {
          (0, _deletePost.default)({
            postId,
            csrf: this.session.csrfToken
          }).catch();
        }
        throw new Error(`We couldn't send your pitch to Karma. ${error.message ? "Rason: " + error.message : ""}`);
      }
    },
    async send() {
      const hasErrors = this.checkErrors();
      if (!hasErrors) {
        (0, _object.set)(this, "loading", true);
        try {
          await this.post();
          this.dispatchToggleModal();
          (0, _object.set)(this, "message", "Thank you! Your pitch was submitted successfully.");
          this.reloadTree();
          window?.open(`/p/${this.postId}`, "_blank");
        } catch (error) {
          (0, _object.set)(this, "errors", [error.message]);
        } finally {
          (0, _object.set)(this, "loading", false);
        }
      }
    },
    toggleModal() {
      const ttl = 100;
      const el = $(`#${this.modalId}`);
      if (this.visible) {
        el.animate({
          opacity: "0",
          transform: "translateY(20px)"
        }, ttl);
        setTimeout(() => el.hide(), ttl * 2);
        (0, _object.set)(this, "visible", false);
      } else {
        el.show();
        el.animate({
          opacity: "1",
          transform: "translateY(0)"
        }, ttl);
        (0, _object.set)(this, "visible", true);
      }
    },
    submit(e) {
      e.preventDefault();
      return (0, _runloop.throttle)(this, this.send, 200);
    },
    didReceiveAttrs() {
      this._super(...arguments);
      if (this.profile?.address) {
        this.fetchDelegatePitch();
        (0, _object.set)(this, "form", {
          ...this.form,
          publicAddress: this.profile.address
        });
      } else {
        (0, _object.set)(this, "customFields", this.fields);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "isOutside", [_object.action], Object.getOwnPropertyDescriptor(_obj, "isOutside"), _obj), _applyDecoratedDescriptor(_obj, "toggleModal", [_object.action], Object.getOwnPropertyDescriptor(_obj, "toggleModal"), _obj), _applyDecoratedDescriptor(_obj, "submit", [_object.action], Object.getOwnPropertyDescriptor(_obj, "submit"), _obj)), _obj));
});