define("discourse/plugins/karma-score/discourse/components/proposal-banner", ["exports", "@ember/component", "@ember/service", "discourse-common/utils/decorators", "@ember/object", "discourse/plugins/karma-score/lib/voting-history/gql/on-chain-fetcher", "discourse/plugins/karma-score/lib/voting-history/gql/off-chain-fetcher", "discourse/plugins/karma-score/lib/get-gov-addr-from-yml", "discourse/plugins/karma-score/lib/fetch-snapshot-onchain-ids"], function (_exports, _component, _service, _decorators, _object, _onChainFetcher, _offChainFetcher, _getGovAddrFromYml, _fetchSnapshotOnchainIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("router.currentURL"), (_obj = {
    router: (0, _service.inject)(),
    openClass: "",
    proposals: [],
    availableToShow: 0,
    bannerHeight: "0",
    fetched: false,
    tokenContract: "",
    daoName: "",
    availableDaos: [],
    logo: (0, _object.computed)(function () {
      return this.siteSettings.Custom_banner_icon_url || this.siteSettings.logo;
    }),
    init() {
      this._super(...arguments);
      this.daoName = window.selectedDao;
      (0, _object.set)(this, 'availableDaos', this.siteSettings.DAO_names?.split(",").map(name => ({
        name,
        select: () => this.selectDao(name)
      })));
    },
    selectDao(daoName) {
      if (!daoName) {
        return;
      }
      ;
      if (!this.availableDaos.find(d => d.name === daoName)) {
        return;
      }
      ;
      (0, _object.set)(this, 'daoName', daoName);
      window.selectedDao = daoName;
      (0, _object.set)(this, 'fetched', false);
      this.fetchDataProposals();
    },
    toggleBanner() {
      (0, _object.set)(this, "openClass", this.openClass === "opened" ? "" : "opened");
      this.setBannerHeight();
    },
    async getGovContractAddr() {
      const tokenAddress = await (0, _getGovAddrFromYml.getGovAddrFromYml)(this.daoName);
      (0, _object.set)(this, "tokenContract", tokenAddress);
    },
    async fetchDataProposals() {
      const {
        Banner_past_proposal_days: daysAgo
      } = this.siteSettings;
      // Fix this workaround when voting history is refactored into components
      const graphqlIds = await (0, _fetchSnapshotOnchainIds.fetchDaoSnapshotAndOnChainIds)(this.daoName?.toLowerCase());
      let onChain = [];
      if (this.siteSettings.Show_on_chain_proposals && graphqlIds.onChainId?.length) {
        onChain = await (0, _onChainFetcher.fetchActiveOnChainProposals)([graphqlIds.onChainId].flat(), daysAgo);
      }
      let offChain = [];
      if (this.siteSettings.Show_off_chain_proposals && graphqlIds.snapshotIds?.length) {
        offChain = await (0, _offChainFetcher.fetchActiveOffChainProposals)([graphqlIds.snapshotIds].flat(), daysAgo);
      }
      const proposals = onChain.concat(offChain.filter(proposal => moment(proposal.voteStarts).isBefore(moment()))).sort((a, b) => moment(a.endsAt).isBefore(moment(b.endsAt)) ? 1 : -1);
      (0, _object.set)(this, "proposals", proposals);
      (0, _object.set)(this, "fetched", true);
      (0, _object.set)(this, "availableToShow", proposals.length - 1);
      this.setBannerHeight();
    },
    setBannerHeight() {
      let bannerHeight = 0;
      const el = $(".proposal");
      if (el.length) {
        if (this.openClass !== "opened") {
          bannerHeight = el[0]?.clientHeight ? el[0]?.clientHeight > 235 ? 235 : el[0]?.clientHeight : 0;
        } else {
          el.each((_, item) => {
            bannerHeight += item.clientHeight + 15;
          });
        }
      }
      (0, _object.set)(this, "bannerHeight", bannerHeight + "px");
    },
    shouldShow(currentUrl) {
      return currentUrl === "/";
    },
    didUpdate() {
      this.setBannerHeight();
    },
    didReceiveAttrs() {
      this._super(...arguments);
      this.fetchDataProposals();
      this.getGovContractAddr();
    },
    bannerLinks: (0, _object.computed)(function () {
      const links = [{
        url: this.siteSettings.Banner_forum_rules_link,
        icon: this.siteSettings.Banner_forum_rules_icon,
        text: this.siteSettings.Banner_forum_rules_text
      }, {
        url: this.siteSettings.Banner_gov_docs_link,
        icon: this.siteSettings.Banner_gov_docs_icon,
        text: this.siteSettings.Banner_gov_docs_text
      }, {
        url: this.siteSettings.Banner_custom_link,
        icon: this.siteSettings.Banner_custom_link_icon,
        text: this.siteSettings.Banner_custom_link_text
      }];
      return links;
    })
  }, (_applyDecoratedDescriptor(_obj, "selectDao", [_object.action], Object.getOwnPropertyDescriptor(_obj, "selectDao"), _obj), _applyDecoratedDescriptor(_obj, "toggleBanner", [_object.action], Object.getOwnPropertyDescriptor(_obj, "toggleBanner"), _obj), _applyDecoratedDescriptor(_obj, "shouldShow", [_dec], Object.getOwnPropertyDescriptor(_obj, "shouldShow"), _obj)), _obj)));
});