define("discourse/plugins/karma-score/lib/karma-api-client", ["exports", "discourse/plugins/karma-score/lib/consts", "discourse/plugins/karma-score/lib/is-typeof", "discourse/plugins/karma-score/lib/request"], function (_exports, _consts, _isTypeof, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const localApi = "/karma-score";
  const karmaUrl = _consts.karmaApiUrl;
  class KarmaApiClient {
    constructor(daoName, publicAddress) {
      _defineProperty(this, "daoName", void 0);
      _defineProperty(this, "publicAddress", void 0);
      _defineProperty(this, "voteUrl", void 0);
      _defineProperty(this, "pitchUrl", void 0);
      if (!daoName && publicAddress) {
        throw new Error("Dao Name and Public Address must be defined!");
      }
      this.daoName = daoName;
      this.publicAddress = publicAddress;
      this.voteUrl = `${karmaUrl}/forum-user/${daoName}/vote-reason/${publicAddress}`.toLowerCase();
      this.pitchUrl = `${karmaUrl}/forum-user/${daoName}/delegate-pitch/${publicAddress}`.toLowerCase();
    }
    checkHealth() {
      return (0, _request.request)(`${karmaUrl}/app/env`, null, "GET");
    }
    saveVoteReason(proposalId, reason, csrfToken) {
      let isUpdate = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      (0, _isTypeof.isTypeof)(csrfToken, "string");
      if (!(reason.summary || reason.threadId || reason.recommendation || reason.postId)) {
        throw Error("Missing values for reason.");
      }
      const url = `${localApi}/vote-reason.json`;
      return (0, _request.request)(url, {
        ...reason,
        proposalId,
        publicAddress: this.publicAddress
      }, isUpdate ? "PUT" : "POST", {
        "X-CSRF-Token": csrfToken
      });
    }
    fetchVoteReasons() {
      return (0, _request.request)(this.voteUrl, null, "GET");
    }

    /**
     * Saves the delegate pitch to Karma Api
     * @param {string} pitch.description the actual pitch
     * @param {string} pitch.threadId the thread id
     * @param {string} pitch.postId the post id
     * @param {string} pitch.discourseHandle the discourse username
     */
    saveDelegatePitch(pitch, csrfToken) {
      let isUpdate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      (0, _isTypeof.isTypeof)(csrfToken, "string");
      if (!(pitch.description || pitch.threadId)) {
        throw new Error("Missing values for pitch.");
      }
      return (0, _request.request)(`${localApi}/delegate-pitch.json`, {
        ...pitch,
        publicAddress: this.publicAddress,
        forum: window.location.host
      }, isUpdate ? "PUT" : "POST", {
        "X-CSRF-Token": csrfToken
      });
    }

    /**
     * Fetches the delegate pitch if exists
     */
    fetchDelegatePitch() {
      return (0, _request.request)(this.pitchUrl, null, "GET");
    }
    fetchUser(username) {
      (0, _isTypeof.isTypeof)(username, "string");
      const url = `${karmaUrl}/forum-user/${username}/${this.daoName}`.toLowerCase();
      return (0, _request.request)(url, null, "GET");
    }
    isApiAllowed(csrfToken) {
      return (0, _request.request)(`${localApi}/allowance.json`, null, "GET", {
        "X-CSRF-Token": csrfToken
      });
    }

    /**
     * Get voting summary for moonbeam and moonriver ONLY
     * @returns {Promise<import("karma-score").KarmaApiVotesSummaryRes>
     */
    async fetchVoteSummary() {
      if (!['moonbeam', 'moonriver', 'moonbase'].includes(this.daoName.toLowerCase())) {
        return {
          proposals: [],
          votes: []
        };
      }
      const url = `${karmaUrl}/delegate/${this.daoName}/${this.publicAddress}/voting-history`.toLowerCase();
      return await (0, _request.request)(url, null, "GET");
    }
  }
  var _default = _exports.default = KarmaApiClient;
});