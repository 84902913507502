define("discourse/plugins/karma-score/lib/snapshot/BaseScore", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.BaseScore = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class BaseScore {
    constructor() {
      let proposal = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        choices: []
      };
      let votes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      let strategies = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      let selected = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
      _defineProperty(this, "proposal", {
        choices: []
      });
      _defineProperty(this, "votes", []);
      _defineProperty(this, "strategies", []);
      _defineProperty(this, "selected", []);
      this.proposal = proposal;
      this.votes = votes;
      this.strategies = strategies;
      this.selected = selected;
    }
  }
  _exports.BaseScore = BaseScore;
});